<script setup lang="ts">
const showFloat = ref<boolean>(false);

const refreshShow = () => {
  let startDom = document.getElementsByClassName("float-start")?.[0] as HTMLElement;
  let endDom = document.getElementsByClassName("float-end")?.[0] as HTMLElement;

  if (!startDom || !endDom) {
    return;
  }

  const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;

  //   浏览器窗口（文档）的可视高度
  const clientHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

  if (scrollTop + clientHeight / 2 >= startDom.offsetTop) {
    showFloat.value = true
  } else {
    showFloat.value = false
  }
};
onMounted(() => {
  window.addEventListener("scroll", refreshShow);
})
onUnmounted(() => {
  window.removeEventListener("scroll", refreshShow);
})
const toTop = () => {
  document.documentElement.scrollTo(0, 0);
}
</script>

<template>
  <section class="scrollToTop">
    <div class="centerBlock">
      <div class="floatWrapper" @click="toTop" v-if="showFloat">
        <img src="/img/components/glx/scroll-to-top/箭头上.png" alt="箭头上" class="imgArrow">
        <div class="text">
          顶部
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.scrollToTop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;

  .centerBlock {
    @include glx-center-block;
    position: relative;

    .floatWrapper {
      pointer-events: auto;
      width: 42px;
      height: 88px;
      background-color: #6B6E8B;
      border-radius: 4px;

      position: absolute;
      right: -24px;
      transform: translateX(100%);
      bottom: 301px;

      &:hover {
        cursor: pointer;
        background-color: #1628D1;
      }

      .imgArrow {
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
      }

      .text {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFEFE;
        line-height: 1;
        margin-top: 10px;
        text-align: center;
      }
    }
  }
}
</style>